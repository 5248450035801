import {
  TEntityOverride,
} from 'src/types'
import {
  ICamera,
} from '@obvious.tech/constellation'

const defaultValues = {
  ptzState: {
    pan: 0,
    tilt: 0,
    zoom: 1,
  },
  minmaxPtz: {
    pan: 0,
    tilt: 0,
    zoom: 1,
  },
  ptzController: {
    speed: 0,
  },
}

const override: TEntityOverride<ICamera> = {
  titleField: 'name',
  order: [
    'id',
    'groups',
    'group',
    'name',

    'markerId',
    'location',
    'orientation',
    'tilt',
    'depth',
    'fieldOfView',

    'width',
    'height',
    'fps',

    'subCameraId',
    'surroundingCameraReferences',

    'vms',
    'vmsId',

    'hasPtzCapabilities',
    'minimumPtz',
    'maximumPtz',
    'ptzPresets',
    'ptzState',
    'ptzLock',
  ],
  global: {
    properties: {
      id: {
        displayedName: 'ID',
      },
      groups: {
        displayedName: 'Owners',
        type: 'groups',
      },
      group: {
        displayedName: 'Folder',
      },
      name: {
        displayedName: 'Name',
      },
      markerId: {
        displayedName: 'Marker ID',
      },
      location: {
        displayedName: 'Marker Location',
      },
      orientation: {
        displayedName: 'Orientation',
      },
      tilt: {
        displayedName: 'Tilt',
      },
      depth: {
        displayedName: 'Depth',
      },
      fieldOfView: {
        displayedName: 'Field of View',
      },
      width: {
        displayedName: 'Width',
      },
      height: {
        displayedName: 'Height',
      },
      fps: {
        displayedName: 'FPS',
      },
      subCameraId: {
        displayedName: 'Sub-Camera Name',
        referenceTo: 'videomanager/api/Camera',
        referencedField: 'name',
      },
      surroundingCameraReferences: {
        displayedName: 'Surrounding Cameras',
      },
      vms: {
        displayedName: 'VMS',
        referenceTo: 'videomanager/api/Vms',
        referencedField: 'name',
      },
      vmsId: {
        displayedName: "VMS's Camera ID",
      },
      hasPtzCapabilities: {
        displayedName: 'PTZ Available',
      },
      minimumPtz: {
        displayedName: 'PTZ Minimum',
      },
      maximumPtz: {
        displayedName: 'PTZ Maximum',
      },
      ptzPresets: {
        displayedName: 'PTZ Presets',
      },
      ptzState: {
        displayedName: 'PTZ State',
      },
      ptzLock: {
        displayedName: 'PTZ Lock',
      },
    },
  },
  list: {
    order: ['id', 'groups', 'name', 'group'],
    properties: {
      fps: {
        hidden: true,
      },
      width: {
        hidden: true,
      },
      height: {
        hidden: true,
      },
      ptzPresets: {
        hidden: true,
      },
      ptzController: {
        hidden: true,
      },
      maximumPtz: {
        hidden: true,
      },
      minimumPtz: {
        hidden: true,
      },
      ptzState: {
        hidden: true,
      },
      depth: {
        hidden: true,
      },
      fieldOfView: {
        hidden: true,
      },
      tilt: {
        hidden: true,
      },
      orientation: {
        hidden: true,
      },
      vmsId: {
        hidden: true,
      },
      location: {
        hidden: true,
      },
    },
  },
  create: {
    properties: {
      ptzState: {
        defaultValue: defaultValues.ptzState,
      },
      minimumPtz: {
        defaultValue: defaultValues.minmaxPtz,
      },
      maximumPtz: {
        defaultValue: defaultValues.minmaxPtz,
      },
      ptzController: {
        defaultValue: defaultValues.ptzController,
      },
    },
  },
  edit: {
    properties: {
      ptzController: {
        hidden: true,
        readOnly: true,
      },
      ptzLock: {
        readOnly: true,
      },
      ptzState: {
        readOnly: true,
      },
    },
  },
}

export default override
