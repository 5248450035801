import {
  IAgent,
} from '@obvious.tech/constellation'

import {
  TEntityOverride,
} from 'src/types'

const override: TEntityOverride < IAgent > = {
  title: (record) => (record?.lastname ? `${record.firstname} ${record.lastname}` : record.firstname),
  global: {
    properties: {
      passportId: {
        displayedName: 'Passport',
        referenceTo: 'authentication/api/Passport',
        referencedField: 'username',
      },
      cameraIds: {
        displayedName: 'Cameras',
        referenceTo: 'videomanager/api/Camera',
        referencedField: 'name',
      },
      missionId: {
        displayedName: 'Mission',
        referenceTo: 'missions/api/Mission',
        readOnly: false,
      },
      markerId: {
        referenceTo: 'geolocation/api/Marker',
        referencedField: 'id',
      },
      isConnected: {
        type: 'boolean',
        displayedName: 'Is connected ?',
        readOnly: false,
      },
      location: {
        displayedName: 'Location',
      },
      photoId: {
        displayedName: 'Image',
        referenceTo: 'resources/api/Image',
        referencedField: 'name',
      },
      groups: {
        type: 'groups',
      },
    },
  },
  create: {
    properties: {
      isConnected: {
        hidden: true,
      },
      birthDate: {
        type: 'birth-date',
      },
    },
  },
  edit: {
    properties: {
      isConnected: {
        readOnly: false,
      },
      birthDate: {
        type: 'birth-date',
      },
    },
  },
  list: {
    properties: {
      markerId: {
        hidden: true,
      },
      location: {
        hidden: true,
      },
      cameraIds: {
        hidden: true,
      },
      missionId: {
        hidden: true,
      },
      photoId: {
        hidden: true,
      },
    },
  },
}

export default override
